/*!
 *  MAIN STYLE
 */

///*** FONT ***///
@import url('https://fonts.googleapis.com/css?family=Istok+Web:400,700');

///*** VARIABLES ***///
@import "style-scss-frontend/addons/commons/variables";

///*** MIXIN ***///
@import "style-scss-frontend/mixins/mixins";

///*** EXTENSION ***///
@import "style-scss-frontend/extensions/extensions";

///*** GLOBAL ***///
@import "style-scss-frontend/addons/commons/global";

///*** HEADER ***///
@import "style-scss-frontend/addons/layouts/header";

///*** COMPONENTS ***///
@import "style-scss-frontend/addons/components/typography";
@import "style-scss-frontend/addons/components/modal";

///*** WRAPPER PAGES & POSTS ***///
@import "style-scss-frontend/addons/layouts/pages-posts";

///*** ERRORS ***///
@import "style-scss-frontend/addons/layouts/errors";

///*** FOOTER ***///
@import "style-scss-frontend/addons/layouts/footer";

///*** RESPONSIVE ***///
@import "style-scss-frontend/addons/commons/responsive";