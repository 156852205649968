/*!
 *  STRUCTURE
 */

html {
  overflow-x: hidden;
  font-size: $full;

  &.no-scroll {
    overflow: hidden;
  }

  &.no-overflow, &.no-overflow-modal {
    width: $full;
    height: $full;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:after {
      content: "";
      position: fixed;
      width: $full;
      height: $full;
      min-height: 600px;
      top: 0;
      left: 0;
      background-color: rgba($institutional, .95);
      @include transition(all 0.3s);
      z-index: 18;
    }
  }

  &.no-overflow-modal {
    &:after {
      background-color: rgba($institutional, .4);
    }
  }
}

html, body {
  height: $full;
  font-family: 'Istok Web', sans-serif;
  @include text-size-adjust(none);
  @extend .reset-margin-padding;
  min-height: $full;
  background: $background_body;
  color: $institutional;
  line-height: 1.2em;
}

body {
  height: auto !important;

  &.no-scroll {
    overflow: hidden;
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.didot {
  font-family: DidotLTPro-Roman;
  font-weight: normal;
  font-style: normal;
}

/* ---------------------------- Selections ----------------------------------- */
::-moz-selection {
  background: $institutional;
  color: $white;
}

::selection {
  background: $institutional;
  color: $white;
}

/* ---------------------------- Reset ----------------------------------- */
.notransition {
  @include transition(none !important);
}

* {
  @extend .reset-margin-padding;
  text-shadow: none;
  outline: medium none !important;
}

*:before, *:after {
  @extend .reset-margin-padding;
  @include box-sizing(border-box);
}

*:hover {
  @include transition(0s);
}

a:hover, a:focus {
  text-decoration: none;
}

.clear {
  clear: both;
}

.reset-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.reset-margin-padding {
  margin: 0;
  padding: 0;
}

.full-width-height {
  width: $full;
  height: $full;
}

.padding-top,
.padding-right,
.padding-bottom,
.padding-left {
  padding-top: 40px;
}

input {
  border: none;
  outline: none;
}

img {
  max-width: $full;
}

/* ---------------------------- Basic components ----------------------------------- */
.mycursor-view, .mycursor-drag {
 width: 50px;
  height: 50px;
  position: absolute;
  display: none;
}

.blueimp-gallery>.next, .blueimp-gallery>.prev, .blueimp-gallery>.close {
  color: $white !important;
}

span svg {
  width: 30px;
  height: 30px;
}

svg {
  vertical-align: middle;
}

button.close {
  border: 0 !important;
}

button, .btnn {
  width: auto;
  height: 30px;
  line-height: 31px;
  padding: 0 18px;
  background-color: $institutional;
  border: 1px solid transparent;
  color: $white;
  display: inline-block;
  text-transform: uppercase;
  font-size: $rem_13px;

  &:hover, &:focus {
    background-color: transparent;
    border: 1px solid $institutional;
    color: $institutional;
    opacity: 1;

  }
}

hr {
  &.xs-small-fillet {
    width: 15px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.small-fillet {
    width: 30px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.medium-fillet {
    width: 70px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.large-fillet {
    width: 110px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.full-fillet {
    width: $full;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }
}

ul.floating-elements {
  width: $full;
  display: inline-block;
  list-style-type: none !important;
  margin: 0 !important;

  li {
    margin: 0 0 20px 0 !important;
    float: left !important;
    color: inherit !important;
  }
}

.text-noresult {
  text-align: center;
  font-size: $rem-20px;
  font-weight: 300;
  color: $grey2;
}

.delay0 {
  -webkit-animation-delay: -0.1s;
  animation-delay: 0.1s;
}

.delay {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay1 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay3 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay4 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay5 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay6 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay7 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay8 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay9 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay10 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay11 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay12 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

// tooltip
.tooltip-inner {
  background-color: $grey1;
  color: $white;
}

.tooltip.top .tooltip-arrow {
  border-top-color: $grey1;
}

// modal
.modal {

  .modal-title {
    font-weight: 400;
    color: $institutional;
  }

  .text-modal {
    font-size: $rem-16px;
    line-height: 1.6rem;

    a {
      color: $institutional;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul {
    margin-left: 20px;
  }
}

// cookie
#cookiesdirective {
  font-size: $rem-13px !important;

  a {
    font-size: $rem-13px !important;
  }
}

#impliedsubmit {
  min-width: 50px;
  height: 25px;
  line-height: 27px;
  background: $institutional;
  margin: 0 auto;
  padding: 0 13px;
}

// jquery ui
.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse
}

.ui-helper-clearfix:after {
  clear: both
}

.ui-dialog {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: .2em;
  outline: 0
}

.ui-dialog .ui-dialog-titlebar {
  padding: .4em 1em;
  position: relative
}

.ui-dialog .ui-dialog-title {
  float: left;
  margin: .1em 0 .1em 80px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis
}

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: .3em;
  top: $medium;
  width: 20px;
  margin: -10px 0 0 0;
  padding: 1px;
  height: 20px
}

.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: .5em 1em;
  background: none;
  overflow: auto
}

.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  margin-top: .5em;
  padding: .3em 1em .5em .4em
}

.ui-widget-content {
  border: 1px solid #ddd;
}

.ui-widget-header {
  background: transparent !important;
  color: $grey2 !important;
}

.ui-widget-content {
  color: $grey2 !important;
}

.ui-dialog .ui-dialog-buttonpane button {
  width: initial;
  height: 33px;
  background: $institutional !important;
  color: $white;
  border: none !important;
  font-size: 14px !important;
  font-weight: 400;
  @include border-radius(2px);
  padding: 0px 10px;
}

.ui-button-text-only .ui-button-text {
  font-size: $rem-13px !important;
  font-weight: 400;
}