/*!
 *  HEADER
 */

/* ---------------------------- Mini navbar front ----------------------------------- */
.min-navbarfront {
  width: $full;
  height: 33px;
  position: fixed;
  top: 0;
  left: 0;
  background: #20292e;
  z-index: 9999;

  ul.user-gest {
    height: 33px;
    display: inline-block;

    li {
      height: 33px;
      line-height: 33px;
      padding: 0 15px;

      &:hover, &:focus {
        background-color: #343d42;
      }

      i {
        vertical-align: text-top;
        padding-right: 3px;
      }

      a {
        display: block;
        color: $grey6;
        font-size: $rem-14px;
        font-weight: 400;
      }
    }
  }

  ul.user-logged {
    height: 33px;
    display: inline-block;
    float: right;

    li.user-menu {
      height: 33px;
      float: right;
      padding: 0 15px;
      line-height: 33px;

      &:hover, &:focus {
        background-color: #343d42;
      }

      .user-imageS {
        max-height: 25px;
        margin-right: 5px;
      }

      &.user-menu {
        .dropdown-toggle {
          color: $grey6;
          font-size: $rem-14px;
          font-weight: 400;

          &:after {
            display: none;
          }
        }

        ul.dropdown-menu {
          position: absolute;
          right: 15px;
          left: auto;
          border: 1px solid $grey6;
          border-radius: 3px;
          @include box-shadow(0px 4px 8px 1px rgba(0,0,0,0.10) !important);
          -webkit-animation-duration: 0.3s;
          -moz-animation-duration: 0.3s;
          -ms-transition-animation-duration: 0.3s;
          margin-top: 2px;

          &:before, &:after {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-width: 7px 8px;
            border-style: solid;
            border-color: transparent;
            z-index: 1001;
          }

          &:before {
            top: -15px;
            right: 27px;
            border-bottom-color: $grey5;
          }

          &:after {
            top: -14px;
            right: 27px;
            border-bottom-color: $white;
          }

          li.user-body {
            padding: 8px 15px;
            margin: 0;

            ul {
              li {
                margin: 0;
                padding: 0;
                text-align: center;
                line-height: 28px;
              }
            }
          }

          li.divider {
            margin: 0!important;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}

/* ---------------------------- Header ----------------------------------- */
header {
  width: $full;
  height: 65px;
  line-height: 65px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
  @include transition(all 0.4s);

  &.h-visible {
    z-index: 999;
  }

  &.down {
    top: 0px;
  }

  &.up {
    top: -65px;
  }

  .logotype {
    img {
      max-width: 80% !important;
      height: auto;
      @include transition(all 0.3s);
    }
  }

  nav {
    height: 65px;
    text-align: center;
    float: right;

    ul {
      margin: 2px 0 0 0;
      list-style-type: none;
      display: inline-block;

      li {
        float: left;
        margin-right: 7px;
        padding-right: 7px;

        &:last-child, &.search-link {
          margin-right: 0;
          padding-right: 0;
        }

        a, span {
          font-size: $rem-15px;
          text-decoration: none;
          color: $white;
          display: inline-block;
          position: relative;

          &:hover, &:focus {
            text-decoration: none;
            cursor: pointer;
          }
        }

        svg {
          width: initial;
          height: initial;
          max-height: 20px;
          vertical-align: middle;
        }

        &.active a:before {
          content: "";
          width: 20px;
          height: 1px;
          position: absolute;
          top: 15px;
          left: $medium;
          margin-left: -10px;
          background-color: $white;
        }

        &:hover, &:focus {
          opacity: 0.8;
        }
      }

      .hamburger {
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        overflow: visible;

        .hamburger-box {
          width: 25px;
          height: 15px;
          display: inline-block;
          position: relative;

          .hamburger-inner {
            display: block;
            top: 10px;
          }
        }

        abbr {
          font-size: $rem-15px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }

      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: $full;
        height: 2px;
        background-color: $grey8;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }

      .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block;
      }
      .hamburger-inner::before {
        top: -6px;
      }

      .hamburger-inner::after {
        bottom: -6px;
      }

      // spin
      .hamburger-spin .hamburger-inner {
        transition-duration: 0.22s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      .hamburger-spin .hamburger-inner::before {
        transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
      }

      .hamburger-spin .hamburger-inner::after {
        transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      .hamburger-spin.is-active abbr {
        color: $white !important;
      }

      .hamburger-spin.is-active .hamburger-inner {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        background-color: $white !important;
      }

      .hamburger-spin.is-active .hamburger-inner::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
        background-color: $white;
      }

      .hamburger-spin.is-active .hamburger-inner::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        background-color: $white;
      }
    }
  }
}

.two-col header {
  nav {
    ul li {
      a, span {
        color: $institutional;
      }

      &.active a:before {
        background-color: $institutional;
      }

      &:hover, &:focus {
        opacity: 0.8;
      }

      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: $institutional;
      }
    }
  }
}