/*!
 *  PAGES-POSTS
 */

.main {
  width: $full;
  height: auto;
  margin: $no-margin;

  section.initial-part-full-col {
    width: $full;
    height: 350px;
    background-color: $institutional;

    .container {
      height: $full;
    }

    .focus-middle-information {

      .title-page-post span {
        margin: 180px 0 0 4px;
      }
    }
  }

  .focus-middle-information {
    position: absolute;
    right: 0;
    left: 0;
    top: $medium;
    @include transform(translateY(-50%));

    .title-page-post {
      @extend .extend-title-page-post;
    }
  }

  // homepage and single (detail work)
  section.focus-work-evidence {
    width: $full;
    min-height: 650px;
    position: relative;

    .image-evidence {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: $institutional;
      background-repeat: no-repeat;
      background-position: center center;
      @include background-size(cover);

      .overlay {
        content: "";
        width: $full;
        height: $full;
        position: absolute;
        top: 0;
        left: 0;
      }

      .container {
        height: $full;
      }

      .focus-middle-information {
        .title-page-post {
          @extend .extend-title-page-post;
        }

        .headline-work {
          position: relative;

          h1 {
            font-size: $rem-28px * 3;
            color: $white;
            line-height: 0.9em;
            margin: 0 0 20px 0;
          }

          span {
            display: block;
            font-size: $rem-18px;
            color: $white;

            hr {
              margin-right: 6px;
              border-color: $white;
            }
          }
        }
      }

      .focus-bottom-information {
        width: $full;
        height: auto;
        position: absolute;
        @include transition(all 0.3s);

        h6 {
          font-family: 'Istok Web', sans-serif !important;
          font-size: $rem-14px;
          color: $white;
          line-height: 1.3em;

          .scroll-to {
            color: $white;
            float: right;
            margin-right: 30px;

            svg {
              position: relative;
            }

            &:hover {
              color: $grey7;

              svg {
                top: 3px;
              }
            }
          }
        }
      }
    }
  }

  section.short-about {
    width: $full;
    padding-top: 60px;
    background: $background_body;
    position: relative;
    z-index: 2;

    h2 {
      font-size: $rem-40px;
      line-height: 1.1em;
    }

    hr {
      margin-top: 80px;
    }

    h5 {
      font-size: $rem-18px;
    }
  }

  section.wrap-post {
    width: $full;
    height: 100%;
    padding-top: 60px;
    background: $background_body;
    position: relative;;
    z-index: 2;

    h3.sketch-heading {
      font-size: $rem-30px;
      line-height: 1.2em;
      color: $institutional;
      margin-bottom: 15px;
    }

    figure.sketch-work {
      width: $full;
      height: 320px;
      margin: 20px 0 0 0;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: center center;
      @include background-size(cover);
    }

    .images-sketch {
      max-width: 80%;
      margin-top: 30px;

      img {
        cursor: none;
      }
    }

    .caption-sketch {
      margin-top: -20px;

      span {
        display: block;
        margin: 10px 0 0 0;
        font-size: $rem-13px;

        hr {
          margin-right: 5px;
        }
      }
    }

    .text-content {
      p:first-child {
        padding-top: 5px;
      }
    }

    .gallery-work, .gallery-exhibition {
      width: $full;
      margin: 60px 0 0 0;
      cursor: none;

      .slick-slide {
        width: auto;
        height: 430px;
        min-height: 300px;
        margin: 0 0 0 15px;
        background-color: $white;
        overflow: hidden;

        img {
          height: $full;
        }
      }
    }

    .slk-bottom-info {
      padding: 20px 0 60px 0;

      .slk-caption {
        @extend .extend-wrap-item;
      }

      .slk-controls {
        float: right;

        span {
          font-size: $rem_30px;
          display: inline-block;

          svg {
            font-size: $rem_24px;

            &.feather-arrow-left {
              position: relative;
              left: 0;
              @include transition(all 0.3s);

              &:hover {
                left: -5px;
              }
            }

            &.feather-arrow-right {
              position: relative;
              right: 0;
              @include transition(all 0.3s);

              &:hover {
                right: -5px;
              }
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

    }

    nav.navigation-posts {
      @extend .extend-navigation-posts;
    }
  }

  // single (other detail)
  &.detail .wrap-post {
    padding-top: 60px;

    h3 {
      font-size: $rem-21px * 2;
      line-height: 1.1em;
    }

    abbr {
      font-size: $rem-14px;
    }

    hr {
      margin: 3px 0;
    }

    span {
      display: block;
      font-size: $rem-15px;
      line-height: 1.3em;
      margin-top: 3px;
    }

    .images-poster {
      max-width: 80%;
      margin-top: 20px;

      img {
        cursor: none;
      }
    }

    .sharing {
      margin: 30px 0;

      abbr {
        font-size: $rem-14px;
      }

      nav {
        margin-top: 10px;

        a {
          margin-right: 5px;

          svg {
            max-height: 20px;
          }
        }
      }
    }
  }

  .wrap-post.multiworks {
    .gallery-work .slick-slide {
      margin: 0 0 40px 15px !important;

      span.caption {
        width: 160px;
        position: absolute;
        padding-top: 10px;
        font-size: $rem-12px;
        line-height: 1.4em;
        color: $institutional;
      }
    }

    .slk-caption {
      display: none !important;
    }
  }

  // categories-work and categories-exhibition
  &.categories.two-col {
    @extend .extend-two-col;

    .content-wrap-two-col {
      .content-text {
        margin-top: 30vh;
      }
    }
  }

  // category results
  &.category-res.full-col {
    section.all-list-item {
      padding: 40px 0 80px 0;

      h3 {
        font-size: $rem-32px;
        line-height: 1.1em;
      }

      .wrap-item-media {
        width: $full;
        list-style-type: none;
        padding: 0;
        margin: 80px 0 0 0;

        .item {
          float: none;
          width: $full;
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: relative;

          &:first-child {
            margin-top: 0;
          }

          @extend .extend-wrap-item;

          .featured-image {
            width: $full;
            height: 320px;
            @include transition(all 0.3s);
            overflow: hidden;
            position: relative;

            figure {
              width: $full;
              height: $full;
              background-repeat: no-repeat;
              @include background-size(cover);
              background-position: center center;
              @include transition(all 0.3s);
              margin: 0 !important;
            }

            a {
              width: $full;
              height: $full;
              position: absolute;
              top: 0;
              left: 0;
              text-indent: -9999px;
            }
          }

          &:hover {
            //background-color: $institutional;

            h1 {
              @include transform(translateX(-30px));

              a {
                //color: $white;

                //&:hover { color: $white; }
              }
            }

            //abbr, span { color: $white; }

            .featured-image {
              @include transform(translateX(15px));
            }
          }
        }
      }

      .wrap-item-text {
        width: $full;
        margin: 80px 0 0 0;

        .item {
          margin-top: 60px;
          position: relative;

          &:nth-child(1), &:nth-child(2) {
            margin-top: 0;
          }

          @extend .extend-wrap-item;
        }
      }

      .navigation-pages {
        margin-top: 80px;

        span {
          &:nth-of-type(3) {
            display: none;
          }

          a {
            font-size: $rem-28px;
            color: $grey5;

            &:hover, &:focus {
              color: $institutional;
            }
          }
        }

        ul {
          text-align: center;
          list-style-type: none;

          li {
            display: inline;
            float: none;
            margin: 0;

            a {
              font-family: $didot;
              font-size: $rem-32px;
              color: $grey5;

              &:hover {
                color: $institutional;
              }
            }

            &.current {
              a {
                font-size: $rem-40px;
                color: $institutional;
              }
            }
          }
        }
      }
    }
  }

  // publications, video and biography
  &.publications.two-col, &.video.two-col, &.biography.two-col {
    @extend .extend-two-col;

    section.wrap-two-col {
      .biography-bottom-information {
        width: $full;
        height: auto;
        position: absolute;
        bottom: 0;

        p {
          font-size: $rem-14px;
          color: $white;

          strong, b {
            color: $white;
          }
        }

        p {
          font-size: $rem-13px;
          line-height: 1.3em;
          color: $white;

          a {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.video.two-col {
    .wrap-two-col {
      aside {
        .content-text {
          padding-top: 130px !important;

          .videodetector {
            iframe {
              width: $full;
              height: 185px;
              float: left;
              margin: 0 2% 20px 2%;
            }

            input.remove-videodetector {
              display: none;
            }
          }
        }
      }
    }
  }

  &.publications.two-col {
    .wrap-two-col {
      aside {
        .content-text {
          margin-top: 130px;

          h3.category-title {
            margin-bottom: 20px;
          }

          .content-publications {
            margin-bottom: 40px;

            .publications-cover {
              img {
                width: $full;
                max-width: 175px;
                height: auto;
              }
            }

            .publications-caption {
              padding-left: 15px;

              p {
                font-size: .9375rem !important;
                letter-spacing: -0.7px !important;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  // results search
  &.search-results {
    .list-item-results {
      padding: 40px 0 80px 0;

      h3 {
        font-size: $rem-32px;
        line-height: 1.1em;
      }

      hr {
        margin: 3px 0;
      }

      span {
        display: block;
        font-size: $rem-15px;
        line-height: 1.3em;
        margin-top: 3px;
      }

      .content-results {
        margin-top: 0px;

        .type-result {
          font-size: $rem-15px;
          margin-top: 10px;
        }

        ul.list-search-results {
          li {
            h1 {
              width: 60%;
              font-size: $rem-36px;
            }

            p {
              font-size: $rem-18px;
            }
          }

          hr {
            clear: both;
            margin-bottom: 30px;

            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }

  // 404 error
  &.not-found.two-col {
    @extend .extend-two-col;

    .content-wrap-two-col {
      .content-text {
        margin-top: 30vh;

        h2 {
          margin: 0 0 15px 0 !important;
        }

        h3 {
          margin-bottom: 30px;
        }
      }
    }
  }
}