/*!
 *  TYPOGRAPHY
 */

h1 {
  font-size: $rem-32px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
}

h2 {
  margin: 0;
  font-size: $rem-26px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
}

h3 {
  font-size: $rem-22px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
}

h4 {
  font-size: $rem-18px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
}

h5 {
  font-size: $rem-14px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
}

h6 {
  font-size: $rem-10px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: DidotLTPro-Roman !important;
}

.institutional-background {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    color: $white;
  }
}

p {
  margin: 0 0 1.55em 0;
  padding: 0;
  color: $grey1;
  font-size: $rem-19px;
  font-weight: 400;
  line-height: 1.55em;
}

.small {
  font-size: $rem-14px !important;
  line-height: 1.5em;
}

.medium {
  font-size: $rem-20px !important;
  line-height: 1.3em;
}

.large {
  font-size: $rem-26px !important;
  line-height: 1.3em;
}

.main-title {
  font-size: $rem-24px !important;
  font-weight: 300;
  line-height: 1.4em;
}

.main-title-medium {
  font-size: $rem-28px !important;
  font-weight: 300;
  line-height: 1.1em;
}

.main-title-large {
  font-size: $rem-36px !important;
  font-weight: 300;
  line-height: 1.1em;
}

.side-heading {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 0.9em;
  color: $grey6;
}

b, strong {
  color: $grey1;
  font-weight: 700;
}

a {
  color: $institutional;

  &:hover, &:focus {
    color: $grey2;
    text-decoration: none;
    outline: medium none;
    text-shadow: none;
  }
}

a.notext {
  text-decoration: none !important;
  padding: 0 5px;
}

blockquote {
  border-left: 4px solid $grey5 !important;
  margin: 20px 0 !important;
  padding: 0 20px;

  p {
    font-size: $rem-20px;
    font-weight: 600;
    font-style: italic;
    line-height: 1.55em;
  }
}

.note {
  font-size: $rem-16px;
  font-weight: 300;
}

abbr {
  font-size: $rem-24px;
}

.list-style-default {
  padding: 0;
  margin-left: 20px;
  list-style: initial;

  li {
    padding: 0;
    margin: 0 0 5px 0;
    float: none;
    color: $grey2;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin-left: 5px;
  float: left;

  &:first-child {
    margin-left: 0;
  }

  a {
    color: $institutional;
  }
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.align-left {
  float: left;
  text-align: left !important;
}

.align-right {
  float: right;
  text-align: right !important;
}