/*!
 *  FOOTER
 */

footer {
  width: $full;
  height: auto;
  padding: 40px 0 10px 0;
  margin-top: -2px;
  background-color: $white;
  position: relative;

  p {
    font-size: $rem-13px;
    color: $grey2;

    a {
      color: $grey2;

      &:hover, &:focus {
        color: $institutional;
      }
    }
  }

  a.scroll {
    display: block;

    &:hover, &:focus { color: $institutional; }

    &:hover span:first-child {
      top: -5px;
    }

    span {
      display: block;
      text-align: center;
      @include transition(all 0.3s);

      &:first-child {
        font-size: $rem-30px;
        margin: -35px 0 5px 0;
        position: relative;
        top: 0;
      }
    }
  }
}
