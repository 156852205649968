/*!
 *  RESPONSIVE
 */

/* ---------------------------- Other full ----------------------------------- */
@media only screen and (min-width: 2240px) {
  html {
    font-size: $full + 20% !important;
  }

  .container {
    width: 1700px;
  }

  p {
    line-height: 1.6em !important;
  }

  .modal {
    .modal-dialog {
      width: $medium !important;
      max-width: $medium !important;
    }
  }
}

/* ---------------------------- Desktop large XL ----------------------------------- */
@media only screen and (min-width: 1920px) {
  html {
    font-size: $full + 05% !important;
  }

  .container {
    width: 1500px;
  }

  p {
    line-height: 1.6em !important;
  }

  .modal {
    .modal-dialog {
      width: 40% !important;
      max-width: 40% !important;
    }
  }
}

/* ---------------------------- Desktop small or Tv SMART ----------------------------------- */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* ---------------------------- Tablet portrait ----------------------------------- */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  html {
    font-size: 90% !important;
  }

  header {
    .logotype img {
      max-width: 90% !important;
    }
  }

  section.wrap-two-col .focus-middle-information {
    h2 {
      font-size: $rem-28px !important;
    }
  }

  .main {
    &.video.two-col {
      .wrap-two-col {
        aside {
          .content-text {
            iframe {
              width: $full;
              height: 200px;
              float: none;
              margin: 0 0 20px 0;
            }
          }
        }
      }
    }
  }
}

/* ---------------------------- Mobile landscape ----------------------------------- */
@media only screen and (max-width: 767px) {
  header {
    z-index: 1;
    nav ul li {
      a, span {
        font-size: $rem_17px !important;
      }
    }
  }

  .two-col header nav ul li a, .two-col header nav ul li span {
    color: $white;

    &:hover, &:focus {
      color: $white;
    }
  }

  .two-col header nav ul li .hamburger-inner, .two-col header nav ul li .hamburger-inner::after, .two-col header nav ul li .hamburger-inner::before {
    background-color: #fff;
  }

  .min-navbarfront ul.user-logged li.user-menu span {
    display: none;
  }

  .main {
    section.initial-part-full-col {
      height: 140px;
    }

    section.focus-work-evidence .headline-work {
      h1 {
        font-size: $rem-24px * 3 !important;
      }
    }

    #anchorAbout, #anchorScroll {
      @include box-shadow(0px -33px 77px -23px rgba(0,0,0,0.75));
    }

    section.wrap-post {
      .gallery-work .slick-slide figure {
        height: 320px;
      }
    }

    &.publications.two-col .animated, &.video.two-col .animated, &.biography.two-col .animated, &.categories.two-col .animated, &.not-found.two-col .animated {
      animation: none !important;
    }

    // categories
    &.categories.two-col section.wrap-two-col {
      height: auto !important;

      figure.featured-image-category {
        width: $full;
        height: 320px;
        position: relative;
        margin: 0;
        display: block !important;
      }

      .focus-middle-information {
        display: none;
      }

      .content-wrap-two-col {
        width: $full;
        height: auto;
        position: relative;

        .content-text {
          margin: 4px 0 0 0;
          padding: 40px 20px 0 20px;

          h2 {
            font-size: $rem-26px !important;
          }
        }
      }
    }

    // results
    &.category-res.full-col {
      section.all-list-item {
        background-color: $white;
        padding: 40px 0 0 0;
        position: relative;
        z-index: 2;

        .itm-space {
          margin-top: 60px;
        }

        .wrap-item-text .item:nth-child(2) {
          margin-top: 60px;
        }

        .wrap-item-media .item {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            h1 {
              @include transform(translateX(0));
            }

            .featured-image {
              @include transform(translateX(0));
            }
          }

          .featured-image {
            margin-top: 15px;
          }
        }
      }
    }

    // publications, video and biography
    &.publications.two-col, &.video.two-col, &.biography.two-col {
      background-color: $institutional;

      section.wrap-two-col {
        height: auto !important;
        z-index: 2;
        margin-top: 120px;

        .focus-middle-information {
          @include transform(none);
          padding: 30px 0 60px 0;
          position: relative;
        }

        .biography-bottom-information {
          padding: 15px 0;
          position: relative;
        }

        .content-wrap-two-col {
          width: $full;
          height: auto;
          position: relative;

          .featured-image-bio {
            margin: 30px 0 0 95px;
          }

          .content-text {
            width: 540px;
            padding: 30px 15px;
            margin: auto;
          }
        }
      }
    }

    // publications
    &.publications.two-col {
      .wrap-two-col {
        .content-wrap-two-col {
          .content-text {
            padding: 30px 0 !important;
          }
        }
      }
    }

    // video
    &.video.two-col {
      .wrap-two-col {
        .content-wrap-two-col {
          .content-text {
            padding: 30px 0 !important;

            iframe {
              margin: 0 0 20px 0 !important;
            }
          }
        }
      }
    }

    // 404 error
    &.not-found.two-col section.wrap-two-col {
      height: auto !important;

      figure.featured-image-404 {
        width: $full;
        height: 320px;
        position: relative;
        margin: 0;
      }

      .focus-middle-information {
        display: none;
      }

      .content-wrap-two-col {
        width: $full;
        height: auto;
        position: relative;

        .content-text {
          margin: 0;
        }
      }
    }
  }

  nav.navigation-posts {
    .nv-right {
      margin-top: 40px !important;
    }
  }

  .order-m-1 {
    order: 1;
  }

  .order-m-2 {
    order: 2;
  }

  .order-m-3 {
    order: 3;
  }

  footer {
    a.scroll {
      margin: 60px 0 15px 0;
      display: block;
    }
  }

  .search {
    padding: 15px;

    &::after, &::before {
      border: none;
    }

    .search-form, .search-related {
      width: $full;
    }

    .search-suggestion {
      margin-top: 30px;
    }
  }

  .m-menu-horizontal {
    height: 60vh;

    div {
      ul {

        li {
          width: $full;
          margin: 15px 0 15px 20px;
          text-align: left;

          a {
            font-size: $rem_26px;
          }

          &.active:before {
            margin-left: -$medium;
          }
        }
      }
    }
  }

  .m-menu-bottom { bottom: -60vh; }
}

/* ---------------------------- Mobile portrait ----------------------------------- */
@media only screen and (max-width: 575px) {
  header {
    .logotype img {
      max-width: 200px !important;
    }
  }

  .main {
    section.focus-work-evidence {
      .headline-work {
        margin: 0 15px;

        h1 {
          font-size: $rem-18px * 3 !important;
        }
      }

      .focus-bottom-information h6 .scroll-to {
        float: left !important;
      }
    }

    section.short-about {
      hr.medium-fillet {
        margin-top: 15px;
      }
    }

    section.all-list-item {
      hr.full-fillet {
        width: 30px;
      }
    }

    section.wrap-post {
      .gallery-work .slick-slide figure {
        height: 280px;
        min-height: 280px;
      }
    }

    // publications, video and biography
    &.publications.two-col, &.video.two-col, &.biography.two-col section.wrap-two-col {
      .content-wrap-two-col {
        .featured-image-bio {
          margin: 30px 0 0 15px;
        }

        .content-text {
          width: $full !important;
        }
      }
    }

    // publications
    &.publications.two-col {
      .wrap-two-col {
        aside {
          .content-text {
            .content-publications {
              .publications-cover {
                img {
                  max-width: initial;
                }
              }

              .publications-caption {
                padding-left: 0;
              }
            }
          }
        }
      }
    }

    // video
    &.video.two-col {
      .wrap-two-col {
        aside {
          .content-text {
            iframe {
              height: 200px !important;
              float: none;
            }
          }
        }
      }
    }

    // results
    &.results.full-col section.all-list-item .wrap-item-media .item .featured-image {
      height: 250px;
    }

    // details
    &.detail .wrap-post {
      .images-poster {
        max-width: $full;
      }
    }

    section.wrap-post .images-sketch {
      max-width: $full;
    }
  }

  .slk-bottom-info .slk-controls {
    margin-top: 15px;
  }

  nav.navigation-posts {
    .nv-left {
      justify-content: space-between;

      h4 {
        text-align: right;
      }
    }
    .nv-right {
      justify-content: space-between !important;
      margin-top: 40px !important;

      h4 {
        text-align: left !important;
      }
    }
  }

  .float-xs-right {
    float: right;
  }

  footer {
    p.align-right {
      float: left !important;
      text-align: left !important;
    }
  }
}