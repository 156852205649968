/*!
 *  MODAL
 */

/* Modal menu mobile */
.m-menu {
  background-color: $white;
  position: fixed;
  @include transition(all 0.3s ease);
}

.m-menu-horizontal {
  width: $full;
  height: 35vh;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  
  div {
    width: $full;
    height: $full;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    ul {
      
      li {
        margin: 15px;
        position: relative;
        
        a {
          font-family: DidotLTPro-Roman !important;
          font-size: $rem_24px;
          color: $institutional;

          &:hover, &:focus, &.active {
            color: $grey2;
          }
        }

        &.active:before {
          content: "";
          position: absolute;
          top: -12px;
          left: $medium;
          width: 40px;
          height: 2px;
          margin-left: -20px;
          background-color: $institutional;
        }
      }
    }
  }
}

.m-menu-bottom { bottom: -35vh; }

.m-menu-bottom.m-menu-open {
  bottom: 0;
  left: 0;
}

/* Modal search */
.search {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $institutional;

  //&::before,
  //&::after {
  //  content: '';
  //  width: calc(100% + 15px);
  //  height: calc(100% + 15px);
  //  position: absolute;
  //  pointer-events: none;
  //  border: 1.5em solid $white;
  //  transition: transform 0.5s;
  //}
  //
  //&::before {
  //  top: 0;
  //  left: 0;
  //  border-right-width: 0;
  //  border-bottom-width: 0;
  //  transform: translate3d(-15px, -15px, 0);
  //}
  //
  //&::after {
  //  right: 0;
  //  bottom: 0;
  //  border-top-width: 0;
  //  border-left-width: 0;
  //  transform: translate3d(15px, 15px, 0);
  //}

  .ps__scrollbar-x-rail {
    display: none !important;
  }
}

.btn-search-close {
  width: 30px;
  height: 30px;
  background: url('/dist/images/frontend/cross.png');
  @include background-size(contain);
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 45px;
  display: none;

  &:hover { transform: rotate(90deg) !important; }
}

.js .btn-search-close {
  display: block;
}

.search-form {
  width: 75%;
  margin: 5em 0;

  .search-input {
    width: $full;
    font-size: $rem-32px * 2;
    line-height: 1;
    display: inline-block;
    box-sizing: border-box;
    padding: 0.05em 0;
    color: $white;
    border: 0;
    border-bottom: 2px solid $white;
    background: transparent;
    border-radius: 0;
    -webkit-appearance: none;

    &:focus { outline: none; }

    &::-ms-clear {
      display: none;
    }
  }

  .search-input::-webkit-search-cancel-button,
  .search-input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search-info {
    width: $full;
    font-size: $rem-13px;
    font-weight: 300;
    display: block;
    margin: 0 auto;
    padding: 0.85em 0;
    text-align: right;
    color: $white;
  }
}

.search-related {
  width: 75%;
  pointer-events: none;

  .search-suggestion {
    opacity: 0;
    visibility: hidden;
    width: $full;
    text-align: left;
    transform: translate3d(0, -30px, 0);
    transition: opacity 0.5s, transform 0.5s;

    &.one {
      transition-delay: 0.10s;
    }

    &.two {
      transition-delay: 0.20s;
    }

    &.three {
      transition-delay: 0.30s;

      p span {
        i {
          position: relative;
          left: 0;
          @include transition(all 0.3s !important);
        }

        a {
          color: $grey4;
          pointer-events: auto;
        }

        &:hover {
          a {
            text-decoration: underline;
          }

          i {
            left: 5px;
          }
        }
      }
    }

    &:nth-child(2) { margin: 0 3em; }

    h3 {
      font-family: 'Istok Web', sans-serif !important;
      font-size: $rem-20px;
      line-height: 1.1em;
      margin: 0;
      color: $grey7;
    }

    ul {
      list-style-type: none;
      margin: 0.75em 0 0 0;

      li {
        font-size: $rem-15px;
        line-height: 1.4em;
        color: $grey4;
        pointer-events: all;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    p {
      font-size: $rem-15px;
      line-height: 1.4em;
      margin: 0.75em 0 0 0;
      color: $grey4;
      
      span {
        margin-top: 4px;
        display: block;
        
        i {
          float: right;
        }
      }
    }
  }
}

.js .search {
  width: $full;
  height: 100vh;
  min-height: $full;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}

.js .search-open {
  pointer-events: auto;
  opacity: 1;
}

.search-open::before,
.search-open::after {
  transform: translate3d(0, 0, 0);
}

.btn-search-close {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
}

.search-open .btn-search-close {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  outline: none;
  box-shadow: none;
}

.js .search-form {
  opacity: 0;
  visibility: hidden;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
}

.js .search-open .search-form {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1);
}

.search-open .search-suggestion {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

@media screen and (max-width:40em) {
  .search-form {
    margin: 5em 0 1em;

    .search-input {
      font-size: $rem-28px;
    }
  }
  .btn-search-close {
    font-size: $rem-14px;
    top: 1.5em;
    right: 1.5em;
  }
  .search-info {
    text-align: left;
  }
  .search-suggestion {
    font-size: 80%;
    width: 100%;
  }
  .search-suggestion:nth-child(2),
  .search-suggestion:nth-child(3) {
    display: none;
  }
}