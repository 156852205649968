/*!
 *  EXTENSION
 */

.extend-title-page-post {
  span {
    width: 300px;
    position: absolute;
    top: $medium;
    margin: 150px 0 0 4px;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    padding: 0;
    font-size: $rem-10px;
    line-height: 1.1em;
    text-align: center;
    color: $white;
    z-index: 10;

    a {
      color: $grey5;

      &:hover {
        color: $white;
      }
    }

    hr {
      margin: 0 4px;
      border-color: $white;
    }
  }
}

.extend-featured-image-cv {
  width: $full;
  background-color: $institutional;
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(cover);
  margin: 0;
}

.extend-featured-image-cn {
  width: $full;
  background-color: $institutional;
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(contain);
  margin: 0;
}

.extend-wrap-item {
  h1 {
    width: 90%;
    font-size: $rem-22px * 2;
    line-height: 1.05em;
    @include transition(all 0.3s);

    a:hover, a:focus {
      color: $institutional;
    }
  }

  abbr {
    font-size: $rem-14px;
  }

  hr {
    margin: 3px 0;
  }

  span:nth-of-type(1) {
    display: block;
    font-size: $rem-15px;
    line-height: 1.3em;
    margin-top: 3px;
  }

  span:nth-of-type(2) {
    display: block;
    font-size: $rem-14px;
    line-height: 1.3em;
    margin-top: 3px;
  }
}

.extend-navigation-posts {
  margin-bottom: 80px;

  .nv-left, .nv-right {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    h4 {
      width: $medium;
      font-size: $rem-40px;
      line-height: 1em;
      @include transition(all 0.3s);
    }

    span {
      font-size: $rem-30px;
    }

    a {
      width: $full;
      height: $full;
      position: absolute;
      text-indent: -9999px;
    }
  }

  .nv-left {

    h4 {
      margin-left: 40px;
    }

    &:hover h4 {
      margin-left: 60px;
    }
  }

  .nv-right {
    justify-content: flex-end;

    h4 {
      text-align: right;
      margin-right: 40px;
    }

    &:hover h4 {
      margin-right: 60px;
    }
  }
}

.extend-two-col {
  overflow: hidden;

  section.wrap-two-col {
    width: $full;
    background-color: $institutional;
    position: relative;

    figure.featured-image-category {
      display: none;
      width: calc(50% + 8.333333%);
      height: $full;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      @include background-size(cover);
      background-position: center center;

      &:before {
        content: "";
        width: $full;
        height: $full;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($institutional, 0.2);
      }
    }

    figure.featured-image-404 {
      width: calc(50% + 8.333333%);
      height: $full;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      @include background-size(cover);
      background-position: center center;

      &:before {
        content: "";
        width: $full;
        height: $full;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($institutional, 0.2);
      }
    }

    .container {
      height: $full;
    }

    .focus-middle-information {
      position: absolute;
      right: 0;
      left: 0;
      top: $medium;
      @include transform(translateY(-50%));

      h2 {
        font-size: $rem-30px;
        line-height: 1.3em;
        color: $white;
      }
    }

    .content-wrap-two-col {
      width: calc(50% - 8.333333%);
      height: 100vh;
      background-color: $white;
      position: absolute;
      top: 0;
      right: 0;

      .featured-image-bio {
        width: 170px;
        height: 170px;
        background-repeat: no-repeat;
        background-position: top left;
        @include background-size(cover);
        @include border-radius($medium);
        margin: 130px 0 0 20px;
      }

      .content-text {
        padding: 40px;

        h2 {
          font-size: $rem-34px;
          line-height: 1.3em;
          color: $institutional;
          margin: 0 0 40px 0;
          padding-bottom: 5px;
          position: relative;

          &:last-child {
            margin: 0;
          }

          a {
            color: $institutional;

            &:hover, &:focus {
              color: $institutional;
            }

            span svg {
              opacity: 0;
              visibility: hidden;
              font-size: $rem-30px;
              @include transition(all 0.3s);
            }
          }

          /*&:before {
            content: "";
            width: 80px;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 30px;
            background-color: $institutional;
            @include transition(all 0.3s);
          }*/

          &:hover {
            span svg {
              opacity: 1;
              visibility: visible;
              color: $institutional;
              margin-right: 40px;
            }

            /*&:before { left: 72px; }*/
          }
        }

        h4 {
          font-size: $rem-24px;
          line-height: 1.3em;
          color: $institutional;
          margin: 30px 0 8px 0;

          &:first-child {
            margin: 0 0 8px 0;
          }
        }

        p {
          font-size: $rem-17px;
        }

        .content-video {
          margin-bottom: 40px;
        }
      }
    }
  }
}